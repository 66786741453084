import { Container } from "reactstrap";
import EventList from "./EventList";

export default function EventGroups(props) {
  return (
    <div
      fluid
      style={{
        width: props.width,
        overflowX: "hidden",
        overflowY: "scroll",
        maxHeight: props.maxHeight,
      }}
    >
      <EventList
        events={props.events}
        marginTop={5}
        cardwidth={props.cardwidth}
        cardmargin={props.cardmargin}
      />
    </div>
  );
}

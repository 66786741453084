import { Container } from "reactstrap";
import LocationList from "./LocationList";

export default function LocationGroups(props) {
  return (
    <div
      fluid
      style={{
        width: props.width,
        padding: "20px",
        overflowX: "hidden",
        overflowY: "scroll",

        maxHeight: props.maxHeight,
      }}
    >
      <LocationList
        locations={props.locations}
        marginTop={5}
        cardwidth={props.cardwidth}
        cardmargin={props.cardmargin}
      />
    </div>
  );
}

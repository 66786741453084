import React, { useState } from "react";
import AddButton from "../buttons/AddButton";
import SummaryModal from "./SummaryModal";
import Login from "../common/headers/Login";
import { useStoreActions, useStoreState } from "easy-peasy";
export default function AddSummary(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };
  const isLogged = useStoreState((state) => state.chapter.isLogged);
  return (
    <div>
      <AddButton onClick={isLogged ? toggle : toggle1} />
      <Login modal={modal1} toggle={toggle1} />
      <SummaryModal
        modal={modal}
        handleSubmit={handleSubmit}
        toggle={toggle}
        textdata={props.textdata}
      />
    </div>
  );
}

import React from "react";
import Navigation from "../common/headers/Navigation";
import NavigationLogged from "../common/headers/NavigationLogged";
import Title from "../common/headers/Title";
import Footer from "../common/footer/Footer";
import { useStoreState, useStoreActions } from "easy-peasy";
export default function MainLayout(props) {
  const isLogged = useStoreState((state) => state.chapter.isLogged);
  return (
    <div className={props.class}>
      {isLogged ? <Navigation /> : <NavigationLogged />}
      <Title chapterName={props.chapterName}></Title>
      {props.children}
      <Footer></Footer>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { dictStyles } from "../../../consts/styles";
import { useStoreState, useStoreActions } from "easy-peasy";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import Avatar from "@material-ui/core/Avatar";
import EmailIcon from "@material-ui/icons/Email";
function Login(props) {
  const userData = useStoreState((state) => state.chapter.userData);
  const signOut = useStoreActions((actions) => actions.chapter.signOut);
  let img = "";
  if (userData) {
    img = userData.photoURL;
  }

  return (
    <div>
      <Modal
        backdrop="static"
        isOpen={props.modal}
        fade={true}
        toggle={props.toggle}
        style={{ maxWidth: "500px", width: "100%" }}
      >
        <ModalHeader style={dictStyles.titleStyle} toggle={props.toggle}>
          Login
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#1E1E22",
          }}
        >
          <Avatar alt="Remy Sharp" src={img} />
          <p
            style={{
              fontSize: "20px",
              color: "#DFDFDF",
              marginTop: "20px",
              fontWeight: "bold",
            }}
          >
            Hello, there {userData ? userData.displayName.split(" ")[0] : ""}
          </p>
          <p style={{ fontSize: "16px", color: "#DFDFDF" }}>
            <EmailIcon style={{ marginRight: "10px" }} />
            {userData ? userData.email : ""}
          </p>

          <Button
            onClick={() => signOut()}
            style={{
              outline: 0,
              backgroundColor: "red",
              fontWeight: "bold",
              paddingTop: "10px",
              paddingBottom: "10px",

              fontSize: "14px",
            }}
            variant="contained"
          >
            <PowerSettingsNewIcon />
          </Button>
          {/* <div style={{ marginTop: "20px" }}>
            <Button color="warning">Donate</Button>
            <Button
              style={{ marginLeft: "20px" }}
              color="secondary"
              onClick={props.toggle}
            >
              Cancel
            </Button>
          </div> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default React.memo(Login);

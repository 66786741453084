import { thunk, action } from "easy-peasy";
import * as characterapi from "../api/charactersAPI";

const characterModel = {
  data: [],
  characterInfo: null,
  isLoading: true,
  setData: action((state, datas) => {
    state.data = datas;
  }),
  resetData: action((state, datas) => {
    state.isLoading = true;
  }),
  setCharacter: action((state, datas) => {
    state.isLoading = !state.isLoading;
    state.characterInfo = datas;
  }),
  characterSearchData: thunk(async (actions, payload) => {
    characterapi
      .searchCharacters(payload)
      .then((res) => {
        actions.setData(res);
      })
      .catch((err) => {});
  }),
  getCharacter: thunk(async (actions, payload) => {
    characterapi
      .getCharacter({ characterid: payload })
      .then((res) => {
        actions.setCharacter(res[0]);
      })
      .catch((err) => {});
  }),
};
export default characterModel;

import { useStore } from "easy-peasy";
import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
} from "reactstrap";

import { summaryStyle } from "../../consts/styles";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function SummaryModal(props) {
  const [summary, setSummary] = useState(props.textdata);
  const [modal, setModal] = useState(false);

  const update = useStoreActions((actions) => actions.chapter.updateSummary);
  const chapterId = useStoreState((state) => state.chapter.chapterId);

  const toggle = () => setModal(!modal);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };
  const userData = useStoreState((state) => state.chapter.userData);
  const updateSummary = () => {
    const payload = {
      chapterid: chapterId,
      summary: summary,
      userid: userData.uid,
    };
    update(payload);
  };

  const clear = () => {
    setSummary("");
  };

  return (
    <div>
      <Modal
        isOpen={props.modal}
        fade={false}
        toggle={props.toggle}
        style={{ maxWidth: "1000px", width: "100%", borderRadius: 60 }}
      >
        <ModalHeader style={summaryStyle.modalStyle} toggle={props.toggle}>
          <span style={summaryStyle.titileStyle}>MODIFY SUMMARY</span>
        </ModalHeader>
        <ModalBody style={summaryStyle.modalStyle}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                style={{ height: "300px" }}
                type="textarea"
                name="summary"
                id="exampleText"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter style={summaryStyle.modalStyle}>
          {/* <Button
            color="primary"
            onClick={props.toggle}
            disabled={summary.length > 10 ? true : false}
          >
            Add
          </Button>{" "} */}
          <Button color="success" onClick={updateSummary}>
            Update
          </Button>
          <Button color="danger" onClick={clear}>
            Clear
          </Button>
          <Button color="secondary" onClick={props.toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

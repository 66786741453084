import DictionaryItem from "./DictionaryItem";
import { dictStyles } from "../../consts/styles";
function DictionaryList(props) {
  let Dictionarys = props.dictionarywords.map((dictwords, index) => {
    return (
      <div style={{ marginTop: props.marginTop, margin: 5 }}>
        <DictionaryItem words={dictwords} />
      </div>
    );
  });

  return <div style={dictStyles.divStyles}>{Dictionarys}</div>;
}
export default DictionaryList;

import axios from "axios";
import { API_URL } from "../config/URL";

export const searchLocations = (payload) => {
  const URL = API_URL.LOCATION_URL + `/findLocation`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getLocations = (payload) => {
  const URL = API_URL.LOCATION_URL + `/getLocation`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

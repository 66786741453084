import { TitleStyle } from "../../../consts/styles";
import { FcBookmark } from "react-icons/fc";
export default function Title(props) {
  return (
    <div style={TitleStyle.titleStyle}>
      <FcBookmark size="2.5em" />
      <span style={TitleStyle.textStyle}>
        {props.chapterName
          .split(" ")
          .slice(0, 4)
          .map((data) => {
            return data + " ";
          })}
        {props.chapterName.split(" ").length > 4 ? ".." : ""}
      </span>
    </div>
  );
}

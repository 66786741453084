import React, { useState } from "react";
import { Button, Modal, ModalHeader, Form, FormGroup, Input } from "reactstrap";
import CharacterGroups from "./CharacterGroups";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function CharacterSearchModal(props) {
  const [modal, setModal] = useState(false);
  const [characterName, setCharacterName] = useState("");

  const search = useStoreActions(
    (actions) => actions.character.characterSearchData
  );
  const searchedCharacters = useStoreState((state) => state.character.data);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const searchCharacter = () => {
    let payload = {
      name: characterName,
    };
    if (characterName.length > 1) {
      search(payload);
    }
  };

  const handleKeypress = (e) => {
    let payload = {
      name: characterName,
    };

    if (e.key === "Enter" && characterName.length > 1) {
      search(payload);
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.modal}
        fade={false}
        toggle={props.toggle}
        className={props.className}
      >
        <div style={{ borderRadius: 60 }}>
          <Form
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              padding: 20,
              backgroundColor: "#1E1E22",
            }}
            onSubmit={handleSubmit}
          >
            <Input
              type="text"
              name="characterName"
              id="characterName"
              placeholder="Search Characters.. ( min 2 letters )"
              value={characterName}
              onKeyPress={handleKeypress}
              onChange={(e) => setCharacterName(e.target.value)}
            />
            <Button
              style={{ marginLeft: 10 }}
              color="primary"
              onClick={searchCharacter}
            >
              <span style={{ fontFamily: "Fredoka One" }}>Search</span>
            </Button>
          </Form>
          <div style={{ backgroundColor: "#1E1E22", padding: 20 }}>
            <CharacterGroups
              characternames={searchedCharacters}
              maxHeight="390px"
              type="search"
              avatarsize={90}
              inputs="CHAR"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

import Avatar from "@material-ui/core/Avatar";
import { API_URL } from "../../config/URL";
import { avatarStyle } from "../../consts/styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useState, useCallback } from "react";

import CharacterInfo from "./CharacterInfo";

import charImg from "../../images/ava.png";
import charImg1 from "../../images/ava1.png";
import charImg2 from "../../images/ava2.png";
import location_img from "../../images/location.png";

const imageStack = [charImg, charImg1, charImg2];

export default function CharacterAvatar(props) {
  const add = useStoreActions((actions) => actions.chapter.addCharacter);
  const addLoc = useStoreActions((actions) => actions.chapter.addLocation);
  const userData = useStoreState((state) => state.chapter.userData);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const data = useStoreState((state) => state.chapter.data);
  const getCharacter = useStoreActions(
    (actions) => actions.character.getCharacter
  );
  const [modal, setModal] = useState(false);

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const toggle = () => {
    getCharacter(props.characterid);
    setModal(!modal);
  };

  const addCharacter = () => {
    const payload = {
      chapterid: chapterId,
      character: props.character.name,
      cid: props.characterid,
      userid: userData.uid,
    };

    const result = data[chapterId - 1].characters.filter(
      (character) => character.cid === props.characterid
    );

    if (result.length === 0) {
      add(payload);
    }
  };

  const addLocation = () => {
    const payload = {
      chapterid: chapterId,
      name: props.character.name,
      desc: props.character.Description,
      userid: userData.uid, // CHANGE THIS
    };

    const result = data[chapterId - 1].locations.filter(
      (location) => location.name === props.character.name
    );

    if (result.length === 0) {
      addLoc(payload);
    }
  };

  return (
    <div
      style={avatarStyle.divStyles}
      onClick={
        props.inputs == "CHAR"
          ? addCharacter
          : props.inputs == "LOC"
          ? addLocation
          : toggle
      }
    >
      <Avatar
        style={{
          margin: 15,
          marginBottom: 0,
          height: props.avatarsize + "px",
          width: props.avatarsize + "px",
          cursor: "pointer",
        }}
        alt="Remy Sharp"
      >
        {props.inputs !== "LOC" ? (
          <img
            className="MuiAvatar-img"
            src={API_URL.IMAGE_URL + props.character.name + ".jpg"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = imageStack[getRandomInt(3)];
            }}
          />
        ) : (
          <img
            className="MuiAvatar-img"
            src={API_URL.IMAGE_LOCATION_URL + props.character.name + ".jpg"}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = location_img;
            }}
          />
        )}
      </Avatar>

      <span style={{ color: "#BCBCBC", textAlign: "center" }}>
        <span style={{}}>
          {props.inputs == "LOC"
            ? props.character.name
            : props.character.name.split(" ")[0]}
        </span>
      </span>

      <CharacterInfo
        modal={modal}
        toggle={toggle}
        characterid={props.characterid}
      />
    </div>
  );
}

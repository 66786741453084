import React from "react";
import { Button, Modal, Row, Col } from "reactstrap";
import Avatar from "@material-ui/core/Avatar";
import { characterStyle } from "../../consts/styles";
import { useStoreActions, useStoreState } from "easy-peasy";
import SpinnerLoading from "../common/SpinnerLoading";
import CharacterInfoTable from "./CharacterInfoTable";
import { API_URL } from "../../config/URL";

function ChapterInfo(props) {
  const character = useStoreState((state) => state.character.characterInfo);
  const isLoading = useStoreState((state) => state.character.isLoading);
  const isLogged = useStoreState((state) => state.chapter.isLogged);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const userData = useStoreState((state) => state.chapter.userData);

  const deleteChar = useStoreActions(
    (actions) => actions.chapter.deleteCharacter
  );

  const deleteCharacter = () => {
    const payload = {
      chapterid: chapterId,
      cid: props.characterid,
      userid: userData.uid,
    };

    deleteChar(payload);
    props.toggle();
  };
  return (
    <div>
      {isLoading ? (
        <Modal
          isOpen={props.modal}
          fade={false}
          toggle={props.toggle}
          className={props.className}
          style={characterStyle.modalLoadingStyle}
          size="lg"
        >
          <div style={characterStyle.loadingDivStyle}>
            <SpinnerLoading />
          </div>
        </Modal>
      ) : (
        <Modal
          isOpen={props.modal}
          fade={false}
          toggle={props.toggle}
          className={props.className}
          style={characterStyle.modalStyle}
          size="lg"
        >
          <Row xs="1" style={characterStyle.modalRowStyle}>
            <Col style={characterStyle.modalColStyle}>
              <Row xs="2">
                <Col>
                  <div style={characterStyle.divStyle}>
                    <Avatar
                      style={characterStyle.avatarStyle}
                      alt="Remy Sharp"
                      src={API_URL.IMAGE_URL + character.name + ".jpg"}
                    />
                    <span
                      style={{
                        color: "#BCBCBC",
                        fontFamily: "Aclonica",
                        fontSize: "20px",
                        marginTop: 5,
                        textAlign: "center",
                      }}
                    >
                      {character.name}
                    </span>
                  </div>
                </Col>
                <Col style={characterStyle.buttonDivStyle}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Button disabled style={{ margin: 5 }} color="warning">
                      Geneology
                    </Button>{" "}
                    <Button
                      onClick={deleteCharacter}
                      style={{ margin: 5 }}
                      color="danger"
                      disabled={isLogged ? false : true}
                    >
                      Delete
                    </Button>
                    <Button
                      onClick={() => props.toggle()}
                      style={{ margin: 5 }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col></Col>
            <Col>
              <div style={characterStyle.colDivStyles}>
                <CharacterInfoTable characterData={character} />
              </div>
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
}
export default React.memo(ChapterInfo);

export const CARD_COLORS = {
  LV1: "#ff7675",
  LV2: "#74b9ff",
  LV3: "#55efc4",
};

export const COLOR = {
  BLACK: "#1E1E22",
  SECTION_TITEL: "#BCBCBC",
  SECTION_TITEL_LITE: "#DFDFDF",
  DICT_BTN_TXT: "#BCBCBC",
  EVENT_CRD_LINK: "#888888",
  SUMMARY_BODY_TXT: "#A3A3A3",
  CARD_WHITE: "",
  SECTION_BLACK: "#1E1E22",
};

import { thunk, action } from "easy-peasy";
import * as chaptersapi from "../api/chapters";
import { v4 as uuidv4 } from "uuid";
import firebase from "firebase";

const chapterModel = {
  data: [],
  chapterId: 1,
  isLogged: false,
  userData: null,
  wordList: [],
  setData: action((state, datas) => {
    state.data = datas;
  }),
  setIsLogged: action((state, payload) => {
    state.isLogged = payload;
  }),
  setUser: action((state, payload) => {
    state.userData = payload;
  }),
  signOut: thunk(async (actions, payload) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        actions.setIsLogged(false);
      })
      .catch((error) => {});
  }),
  chapterData: thunk(async (actions, userid) => {
    chaptersapi
      .allChapters({ userid })
      .then((res) => {
        actions.setData(res[0].chapters);
      })
      .catch((err) => {});
  }),
  addEvent: thunk(async (actions, payload) => {
    let id = uuidv4();

    let eventData = {
      event: payload.event,
      description: payload.description,
      link: payload.link,
      chapterid: payload.chapterid,
      priority: payload.priority,
      eventid: id,
      userid: payload.userid,
    };
    chaptersapi
      .addEvent(eventData)
      .then((res) => {
        actions.chapterData(payload.userid);
      })
      .catch((err) => {});
  }),
  addCharacter: thunk(async (actions, payload) => {
    let characterData = {
      userid: payload.userid,
      chapterid: payload.chapterid,
      character: payload.character,
      cid: payload.cid,
    };

    chaptersapi
      .addCharacter(characterData)
      .then((res) => {
        if (res.value === null) {
        } else {
          actions.chapterData(payload.userid);
        }
      })
      .catch((err) => {});
  }),
  updateEvent: thunk(async (actions, payload) => {
    console.log(payload);
    chaptersapi
      .updateEvent(payload)
      .then((res) => {
        actions.chapterData(payload.userid);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }),
  deleteEvent: thunk(async (actions, payload) => {
    chaptersapi
      .deleteEvent(payload)
      .then((res) => {
        actions.changeEvent(payload);
      })
      .catch((err) => {});
  }),
  deleteDictionary: thunk(async (actions, payload) => {
    chaptersapi
      .deleteDictionary(payload)
      .then((res) => {
        actions.chapterData(payload.userid);
      })
      .catch((err) => {});
  }),
  getSummary: thunk(async (actions, payload) => {
    chaptersapi
      .getSummary(payload)
      .then((res) => {
        actions.saveSummary(payload);
      })
      .catch((err) => {});
  }),
  wordSearch: thunk(async (actions, payload) => {
    chaptersapi
      .wordSearch(payload)
      .then((res) => {
        actions.setSearchedWordlist(res);
      })
      .catch((err) => {});
  }),
  updateSummary: thunk(async (actions, payload) => {
    chaptersapi
      .updateSummary(payload)
      .then((res) => {
        actions.saveSummary(payload);
      })
      .catch((err) => {});
  }),
  addDictionary: thunk(async (actions, payload) => {
    let id = uuidv4();

    let dicData = {
      wordid: id,
      word: payload.word,
      shorttext: payload.shorttext,
      longtext: payload.longtext,
      chapterid: payload.chapterid,
      userid: payload.userid,
    };
    chaptersapi
      .addDictionary(dicData)
      .then((res) => {
        actions.saveDictionary(payload);
      })
      .catch((err) => {});
  }),
  addLocation: thunk(async (actions, payload) => {
    let locationData = {
      chapterid: payload.chapterid,
      name: payload.name,
      desc: payload.desc,
      userid: payload.userid,
    };

    chaptersapi
      .addLocation(locationData)
      .then((res) => {
        if (res.value === null) {
        } else {
          actions.chapterData(payload.userid);
        }
      })
      .catch((err) => {});
  }),
  deleteLocation: thunk(async (actions, payload) => {
    chaptersapi
      .deleteLocation(payload)
      .then((res) => {
        actions.chapterData(payload.userid);
      })
      .catch((err) => {});
  }),
  deleteCharacter: thunk(async (actions, payload) => {
    chaptersapi
      .deleteCharacter(payload)
      .then((res) => {
        actions.chapterData(payload.userid);
      })
      .catch((err) => {});
  }),
  setChapter: action((state, payload) => {
    state.chapterId = payload.index;
    //chapterData(payload.uid);
  }),
  setSearchedWordlist: action((state, payload) => {
    state.wordList = payload;
  }),
  saveSummary: action((state, payload) => {
    state.data[payload.chapterid - 1].summary = payload.summary;
  }),
  saveEvent: action((state, payload) => {
    state.data[payload.chapterid - 1].events.push(payload);
  }),
  saveDictionary: action((state, payload) => {
    state.data[payload.chapterid - 1].dictionary.push(payload);
  }),
  saveCharacter: action((state, payload) => {
    state.data[payload.chapterid - 1].characters.push({
      name: payload.character,
    });
  }),
  saveLocation: action((state, payload) => {
    state.data[payload.chapterid - 1].locations.push({
      name: payload.character,
    });
  }),
  changeEvent: action((state, payload) => {
    state.data[payload.chapterid - 1].events = state.data[
      payload.chapterid - 1
    ].events.filter((event) => event.eventid !== payload.eventid);
  }),
  changeLocation: action((state, payload) => {
    state.data[payload.chapterid - 1].locations = state.data[
      payload.chapterid - 1
    ].locations.filter((location) => location.name !== payload.name);
  }),
};
export default chapterModel;

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { dictStyles } from "../../../consts/styles";

import logo from "../../../images/logo.png";
import donate from "../../../images/donate.png";

export default function Donate(props) {
  return (
    <div>
      <Modal
        backdrop="static"
        isOpen={props.modal}
        fade={true}
        toggle={props.toggle}
        style={{ maxWidth: "700px", width: "100%", backgroundColor: "#1E1E22" }}
      >
        <ModalHeader style={dictStyles.titleStyle} toggle={props.toggle}>
          Be a Sponsor 👋
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#1E1E22",
          }}
        >
          <img src={logo} width="280px" style={{ marginBottom: "20px" }} />
          <div
            style={{
              color: "#DFDFDF",
              fontWeight: "bold",
              fontSize: "1.2em",
              letterSpacing: "-0.5px",
            }}
          >
            Silmarillion.co is a non-profit project and driven by YOU, the
            community!
          </div>
          <br />
          <div
            style={{
              color: "#DFDFDF",
              fontWeight: "bold",
              fontSize: "1.1em",
              letterSpacing: "-0.5px",
            }}
          >
            The aim is that Tolkien readers can use this website to explore the
            fictive universe, Tolkien lovely filled with life. We have the
            intention to help readers so that those can find the information
            they need. Keeping this website alive costs some bucks. We really
            appreciate if you like Silmarillion.co , please consider a donation.
            This ONLY helps to improve the server and for future implementation
          </div>
          <br />

          <div
            style={{
              color: "#DFDFDF",
            }}
          >
            <p style={{ fontFamily: "Fredoka One", fontSize: "20px" }}>
              Future Implementation
            </p>
          </div>
          <ul style={{ color: "#DFDFDF" }}>
            <li>Include all other tolkien books to the website.</li>
            <li>
              Implement an interactive Map section where users can hover over a
              place in the map and get all the information about that place.
            </li>
            <li>Design and implement tolkien specific Dictionary section.</li>
          </ul>

          <div style={{ marginTop: "20px" }}>
            <img
              src={donate}
              width="170px"
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                window.location.href =
                  "https://paypal.me/supportsilmarils?locale.x=en_US";
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

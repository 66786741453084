import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import locations from "../../api/locations";
import LocationGroups from "../locations/LocationGroups";
import { FaPlaceOfWorship } from "react-icons/fa";
import { mapStyles } from "../../consts/styles";
import AddLocation from "../locations/AddLocation";

function LocationSection(props) {
  return (
    <SectionLayout width="100%">
      <div style={mapStyles.mapDiv}>
        <SectionTitle
          titlename="LOCATIONS"
          icon={<FaPlaceOfWorship size="1.2em" />}
        />
        <AddLocation />
      </div>
      <div style={{ marginTop: 18 }}></div>
      <LocationGroups
        locations={props.chapters.locations}
        maxHeight="487px"
        cardwidth="100%"
        cardmargin={8}
        width="100%"
      />
    </SectionLayout>
  );
}
export default React.memo(LocationSection);

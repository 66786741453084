import React from "react";
import { COLOR } from "../../consts/consts";

export default function SectionLayout(props) {
  return (
    <div
      style={{
        backgroundColor: COLOR.BLACK,
        width: props.width,
        padding: 10,
        paddingBottom: "25px",
      }}
      className={props.class}
    >
      {props.children}
      {/* <SectionTitle titlename="Characters" />
      <CharacterGroups characternames={characters} maxHeight="290px" /> */}
    </div>
  );
}

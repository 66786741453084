import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import EventGroup from "../events/EventGroup";
import { RiCalendarEventLine } from "react-icons/ri";
import AddEvent from "../events/AddEvent";
import { mapStyles } from "../../consts/styles";
function EventSection(props) {
  return (
    <SectionLayout width="100%">
      <div style={mapStyles.mapDiv}>
        <SectionTitle
          titlename="NOTES"
          icon={<RiCalendarEventLine size="1.2em" />}
        />
        <AddEvent />
      </div>

      <EventGroup
        events={props.chapters.events}
        maxHeight="560px"
        cardwidth="96%"
        cardmargin={5}
      />
    </SectionLayout>
  );
}

export default EventSection;

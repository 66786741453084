import React, { useEffect } from "react";
import "../App.css";
import CharactersSection from "../components/sections/CharactersSection";
import DictionarySection from "../components/sections/DictionarySection";
import SummarySection from "../components/sections/SummarySection";
import EventSection from "../components/sections/EventSection";
import MapsSection from "../components/sections/MapsSection";
import LocationSection from "../components/sections/LocationSection";
import MainLayout from "../components/layouts/MainLayout";
import firebase from "firebase";
import styles from "./Main.module.css";
import Loading from "../pages/Loading";

import { useStoreState, useStoreActions } from "easy-peasy";

import MapContainer from "../components/maps/MapContainer";

function App() {
  const userData = useStoreState((state) => state.chapter.userData);
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user !== null) {
          fetchChapters(user.uid);
        } else {
          fetchChapters(1);
        }
      });

    resetData(true);
    return () => unregisterAuthObserver();
  }, []);

  const chapters = useStoreState((state) => state.chapter.data);
  const chapterId = useStoreState((state) => state.chapter.chapterId);

  let currentChapter = chapters[chapterId - 1];

  const fetchChapters = useStoreActions(
    (actions) => actions.chapter.chapterData
  );
  const resetData = useStoreActions((actions) => actions.character.resetData);
  if (chapters.length === 0) {
    return <Loading />;
  }
  return (
    <div style={{ backgroundColor: "#1E1E22" }}>
      <MainLayout chapterName={currentChapter.chaptername}>
        <MapContainer />
        <div className={styles.container}>
          <div className={styles.first_row}>
            <div className={styles.character}>
              <CharactersSection chapters={currentChapter}></CharactersSection>
              <DictionarySection chapters={currentChapter}></DictionarySection>
            </div>
            <div className={styles.summary}>
              <SummarySection chapters={currentChapter}></SummarySection>
            </div>
            <div className={styles.event}>
              <EventSection chapters={currentChapter}></EventSection>
            </div>
          </div>
          <div className={styles.first_row}>
            <div className={styles.map}>
              <MapsSection></MapsSection>
            </div>
            <div className={styles.location}>
              <LocationSection chapters={currentChapter}></LocationSection>
            </div>
          </div>
        </div>
      </MainLayout>
    </div>
  );
}
export default React.memo(App);

import React from "react";
import { characterStyle } from "../../consts/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

function CharacterInfoTable(props) {
  let character = props.characterData;

  const useStyles = makeStyles({
    table: {},
  });

  const classes = useStyles();

  return (
    <div>
      <TableContainer component={Paper} style={characterStyle.tableStyle}>
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {character.other_names !== null && character.other_names !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>OTHER NAMES</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.other_names}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Siblings !== null && character.Siblings !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>SIBLINGS</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Siblings}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.position !== null && character.position !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>POSITION</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.position}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Location !== null && character.Location !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>LOCATION</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Location}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Affiliation !== null && character.Affiliation !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>AFFILIATION</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Affiliation}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Spouse !== null && character.Spouse !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>SPOUSE</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Spouse}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Gender !== null && character.Gender !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>GENDER</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Gender}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Eye_color !== null && character.Eye_color !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>EYE COLOR</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Eye_color}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Clothing !== null && character.Clothing !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>CLOTHING</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Clothing}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Notable_for !== null && character.Notable_for !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>NOTABLE FOR</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Notable_for}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.House !== null && character.House !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>HOUSE</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.House}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Parentage !== null && character.Parentage !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>PARCENTAGE</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Parentage}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Language ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>LANGUAGE</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Language}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Death !== null && character.Death !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>DEATH</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Death}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Birth !== null && character.Birth !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>BIRTH</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Birth}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Height !== null && character.Height !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>HEIGHT</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Height}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}

            {character.Children !== null && character.Children !== "" ? (
              <TableRow key={character._id}>
                <TableCell component="th" scope="row">
                  <span style={characterStyle.tableTextStyle}>CHILDREN</span>
                </TableCell>
                <TableCell align="right">
                  <span style={characterStyle.tableCellStyle}>
                    {character.Children}
                  </span>
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
export default React.memo(CharacterInfoTable);

import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import CharacterGroups from "../characters/CharacterGroups";
import { GiSwordwoman } from "react-icons/gi";
import { mapStyles } from "../../consts/styles";
import AddCharacter from "../characters/AddCharacter";

import CharacterInfo from "../characters/CharacterInfo";

function CharactersSection(props) {
  return (
    <div>
      <SectionLayout width="100%">
        <div style={mapStyles.mapDiv}>
          <SectionTitle
            titlename="CHARACTERS"
            icon={<GiSwordwoman size="1.2em" />}
          />
          <AddCharacter />
        </div>
        <CharacterGroups
          characternames={props.chapters.characters}
          maxHeight="290px"
          avatarsize={100}
        />
      </SectionLayout>
      <CharacterInfo />
    </div>
  );
}
export default React.memo(CharactersSection);

import React from "react";
import { Button } from "reactstrap";
import { RiAddCircleFill } from "react-icons/ri";
const AddButton = ({ onClick, icon }) => (
  <Button
    color="transparent"
    style={{ padding: 0, marginLeft: 6 }}
    onClick={(event) => onClick(event)}
  >
    <span>
      <RiAddCircleFill color="green" size="1.8em" />
    </span>
  </Button>
);

export default AddButton;

import React from "react";
import { mapStyles } from "../../consts/styles";

export default function MapImage(props) {
  const image = props.images[0];
  return (
    <div
      style={{
        height: props.height,
        width: props.width,
        overflow: "hidden",
        marginTop: 15,
        borderRadius: 20,
      }}
    >
      <img src={image} alt="Map View" style={mapStyles.mapImage} />
    </div>
  );
}

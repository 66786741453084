import { thunk, action } from "easy-peasy";
import * as locationapi from "../api/locationAPI";

const LocationModel = {
  data: [],
  characterInfo: null,
  isLoading: true,
  setData: action((state, datas) => {
    state.data = datas;
  }),
  resetData: action((state, datas) => {
    state.isLoading = false;
  }),
  setLocation: action((state, datas) => {
    state.isLoading = !state.isLoading;
    state.characterInfo = datas;
  }),
  characterLocationData: thunk(async (actions, payload) => {
    locationapi
      .searchLocations(payload)
      .then((res) => {
        actions.setData(res);
      })
      .catch((err) => {});
  }),
  getLocation: thunk(async (actions, payload) => {
    locationapi
      .getLocations({ name: payload })
      .then((res) => {
        actions.setLocation(res[0]);
      })
      .catch((err) => {});
  }),
};
export default LocationModel;

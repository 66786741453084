import React, { useState } from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import maps from "../../api/maps";
import MapImage from "../maps/MapImage";
import MapsButtom from "../maps/MapsButton";
import { mapStyles } from "../../consts/styles";
import { FaMapMarkedAlt } from "react-icons/fa";
import IconButton from "../buttons/IconButton";
import MapContainer from "../maps/MapContainer";

import { FaGlobeAmericas } from "react-icons/fa";
function MapSection(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <SectionLayout width="100%">
      <div style={mapStyles.mapDiv}>
        <SectionTitle
          titlename="GEOGRAPHY"
          icon={<FaGlobeAmericas size="1.2em" />}
        />
        <MapsButtom images={maps} />
        {/* <IconButton
          value="MAP"
          icon={<FaMapMarkedAlt size="1.2em" />}
          onClick={() => setIsOpen(true)}
          color="warning"
        /> */}
        <MapContainer toggle={toggle} modal={isOpen} />
      </div>
      <MapImage images={maps} width={"100%"} margin={10} height="500px" />
    </SectionLayout>
  );
}
export default React.memo(MapSection);

import React, { useState } from "react";
import AddButton from "../buttons/AddButton";
import { useStoreActions, useStoreState } from "easy-peasy";

import CharacterSearchModal from "./CharacterSearchModal";
import Login from "../common/headers/Login";

const AddCharacter = () => {
  const setData = useStoreActions((action) => action.character.setData);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setData([]);
  };

  const [modal1, setModal1] = useState(false);

  const toggle1 = () => setModal1(!modal1);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const isLogged = useStoreState((state) => state.chapter.isLogged);

  const chapterId = useStoreState((state) => state.chapter.chapterId);

  return (
    <div>
      <AddButton onClick={isLogged ? toggle : toggle1} />
      <Login modal={modal1} toggle={toggle1} />
      <CharacterSearchModal
        toggle={toggle}
        modal={modal}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AddCharacter;

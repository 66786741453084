import React from "react";
import { Button } from "reactstrap";
import { buttonStyle } from "../../consts/styles";

const IconButton = ({ value, onClick, icon, color }) => (
  <Button
    outline
    color={color}
    style={{ height: "35px", marginLeft: 10, marginBottom: 8 }}
    onClick={(event) => onClick(event)}
  >
    <div style={buttonStyle.iconBtn}>
      {icon}
      <span style={{ marginLeft: 5 }}> {value}</span>
    </div>
  </Button>
);

export default IconButton;

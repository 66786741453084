import { COLOR } from "../../consts/consts";
import { sectionStyle } from "../../consts/styles";
export default function SectionTitle(props) {
  return (
    <div tag="h3" style={{ color: COLOR.SECTION_TITEL }}>
      <span style={sectionStyle.textStyle}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.icon}
          <span style={{ marginLeft: 10 }}>{props.titlename}</span>
        </div>
      </span>
    </div>
  );
}

import React, { useState } from "react";
import AddButton from "../buttons/AddButton";
import { useStoreActions, useStoreState } from "easy-peasy";
import LocationSearchModal from "./LocationSearchModal";
import Login from "../common/headers/Login";
const AddLocation = () => {
  const setData = useStoreActions((action) => action.location.setData);
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setData([]);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const isLogged = useStoreState((state) => state.chapter.isLogged);
  return (
    <div>
      <AddButton onClick={isLogged ? toggle : toggle1} />
      <Login modal={modal1} toggle={toggle1} />
      <LocationSearchModal
        toggle={toggle}
        modal={modal}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AddLocation;

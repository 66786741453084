import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import dictionary from "../../api/dictionary";
import DictionaryGroups from "../dictionary/DictionaryGroups";
import AddDictionary from "../dictionary/AddDictionary";
import { mapStyles } from "../../consts/styles";

import { FaBook } from "react-icons/fa";
function DictionarySection(props) {
  return (
    <SectionLayout width="100%">
      <div style={mapStyles.mapDiv}>
        <SectionTitle titlename="Dictionary" icon={<FaBook size="1.1em" />} />
        <AddDictionary wordlist={props.chapters.dictionary} />
      </div>

      <DictionaryGroups
        dictionary={props.chapters.dictionary}
        maxHeight="195px"
        marginTop="10px"
      />
    </SectionLayout>
  );
}
export default React.memo(DictionarySection);

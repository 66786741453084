import { Container } from "reactstrap";
import DictionaryList from "./DictionaryList";

export default function DictionaryGroups(props) {
  return (
    <div
      fluid
      style={{
        width: props.width,
        padding: "10px",
        overflowY: "scroll",
        maxHeight: props.maxHeight,
        marginTop: props.marginTop,
      }}
    >
      <DictionaryList dictionarywords={props.dictionary} marginTop={5} />
    </div>
  );
}

import { COLOR } from "./consts";

const TitleStyle = {
  titleStyle: {
    height: "100px",
    width: "100%",
    backgroundColor: COLOR.BLACK,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  textStyle: {
    color: COLOR.SECTION_TITEL,
    fontWeight: "bold",
    fontSize: 40,
    fontFamily: "Permanent Marker",
    marginLeft: 15,
  },
};

const sectionStyle = {
  textStyle: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontFamily: "Aclonica",
    fontSize: 22,
    margin: 0,
  },
};

const loadingStyle = {
  loaderStyle: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: COLOR.SECTION_BLACK,
  },
};

const summaryStyle = {
  textStyle: {
    color: COLOR.SUMMARY_BODY_TXT,
    fontFamily: "Yeseva One",
    fontSize: 19,
  },
  modalStyle: {
    backgroundColor: COLOR.BLACK,
  },
  titileStyle: {
    color: COLOR.SECTION_TITEL,
    fontFamily: "Fredoka One",
  },
};

const NavigationStyle = {
  navStyles: {
    backgroundColor: COLOR.BLACK,
  },
};

const avatarStyle = {
  divStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarStyles: {
    margin: 15,
    marginBottom: 0,
  },
};

const dictStyles = {
  buttonStyles: {
    color: COLOR.DICT_BTN_TXT,
  },
  divStyles: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  modalStyle: {
    width: "100%",

    backgroundColor: COLOR.BLACK,
  },
  titleStyle: {
    backgroundColor: COLOR.BLACK,
    color: COLOR.SECTION_TITEL,
    fontFamily: "Fredoka One",
  },
  modalBodyStyle: {
    backgroundColor: COLOR.BLACK,
  },
};

const eventStyles = {
  arefStyles: {
    textDecoration: "none",
    marginLeft: 5,
    color: COLOR.EVENT_CRD_LINK,
    fontFamily: "Fredoka One",
  },
  textStyle: {
    fontFamily: "Fredoka One",
  },
  textDescription: {
    fontSize: 18,
    fontWeight: "500",
  },
};

const mapStyles = {
  mapImage: {
    minWidth: "100%",
  },
  mapDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mapButton: {},
  mapModal: {
    maxWidth: "1335px",

    borderRadius: 60,
  },
  modalHeader: {
    backgroundColor: "#1E1E22",
    alignItems: "center",
  },
  modalHeaderFont: {
    color: COLOR.SECTION_TITEL,
    fontFamily: "Fredoka One",
  },
  modalBody: {
    backgroundColor: "#1E1E22",
  },
};

const footerStyle = {
  mainFooter: {
    backgroundColor: "#101010",
    paddingTop: "3em",
    position: "relative",
    bottom: 0,
    width: "100%",
    fontFamily: "Fredoka One",
    color: COLOR.EVENT_CRD_LINK,
  },
};

const buttonStyle = {
  iconBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const locationStyle = {
  cardBody: {
    backgroundColor: "#E5E5E5",
    borderRadius: 10,
  },
  modalStyles: {
    backgroundColor: "#1E1E22",
  },
  modalText: {
    color: COLOR.SECTION_TITEL,
    fontFamily: "Fredoka One",
  },
};

const characterStyle = {
  divStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: COLOR.SECTION_BLACK,
    borderRadius: 10,
  },
  avatarStyle: {
    margin: 15,
    marginBottom: 0,
    height: "130px",
    width: "130px",
  },
  modalStyle: {
    maxWidth: "1000px",
    width: "100%",
    borderRadius: 60,
  },
  modalLoadingStyle: {
    alignItems: "center",

    width: "100%",
    borderRadius: 60,
    justifyContent: "center",
  },
  loadingDivStyle: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    margin: 10,
  },
  buttonDivStyle: {
    borderRadius: 10,
    alignItems: "center",
    display: "flex",
  },
  modalRowStyle: {
    backgroundColor: COLOR.SECTION_BLACK,
    borderRadius: 20,
  },
  modalColStyle: {
    display: "flex",
    justifyContent: "center",
  },
  colDivStyles: {
    maxHeight: "290px",
    borderRadius: 10,
    margin: 5,
    overflowY: "scroll",
    margin: 30,
  },
  tableStyle: {
    width: "100%",
    backgroundColor: "#C4C4C4",
  },
  tableTextStyle: {
    fontWeight: "bold",
    fontSize: 15,
    fontFamily: "Yeseva One",
  },
  tableCellStyle: {
    fontSize: 18,
  },
  titileStyle: {
    color: COLOR.SECTION_TITEL,
    fontFamily: "Fredoka One",
  },
};

export {
  characterStyle,
  locationStyle,
  buttonStyle,
  footerStyle,
  mapStyles,
  TitleStyle,
  NavigationStyle,
  avatarStyle,
  dictStyles,
  eventStyles,
  sectionStyle,
  summaryStyle,
  loadingStyle,
};

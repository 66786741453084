import React from "react";
import { footerStyle } from "../../../consts/styles";
import { COLOR } from "../../../consts/consts";
import InstagramIcon from "@material-ui/icons/Instagram";
import GitHubIcon from "@material-ui/icons/GitHub";
import EmailIcon from "@material-ui/icons/Email";
function Footer() {
  return (
    <div style={footerStyle.mainFooter}>
      <div className="container">
        <div className="row">
          <div className="col">
            <h4>Books</h4>
            <ui className="list-unstyled">
              <li>The Silmarillion</li>
              <li>Beren and Lúthien</li>
              <li>The Fall of Gondolin</li>
            </ui>
          </div>
          <div className="col">
            <h4>LOTR Books</h4>
            <ui className="list-unstyled">
              <li>The Fellowship of the Ring</li>
              <li>The Two Towers</li>
              <li>The Return of the King</li>
            </ui>
          </div>

          {/* Column3 */}
          <div className="col">
            <h4>Resources</h4>
            <ui className="list-unstyled">
              <li>tolkiengateway.net</li>
              <li>lotr.fandom.com</li>
            </ui>
          </div>

          {/* Column2 */}
          <div className="col">
            <h4>Contact Me</h4>
            <ui className="list-unstyled">
              <li>
                <EmailIcon style={{ marginRight: "10px" }} />
                <a
                  style={{ color: "#EAEAEA" }}
                  href="mailto:info@dulajkavinda.me"
                >
                  info@dulajkavinda.me
                </a>
              </li>
              <li>
                <GitHubIcon style={{ marginRight: "10px" }} />
                <a
                  href="https://github.com/dulajkavinda"
                  style={{ color: "#EAEAEA" }}
                >
                  github/dulajkavinda
                </a>
              </li>
              {/* <li>
                <InstagramIcon style={{ marginRight: "10px" }} />

                <a
                  style={{ color: "#EAEAEA" }}
                  href="https://www.instagram.com/dulaj.kaavinda/"
                >
                  instagram/dulajkavinda
                </a>
              </li> */}
            </ui>
          </div>
        </div>
        <hr style={{ backgroundColor: COLOR.EVENT_CRD_LINK }} />
        {/* <p style={{ fontSize: "13px" }}>
          * i started this as a pet project and now its a not-profit
          application. all the images i used are from google and if you have any
          issues with copyrights please contact me.
        </p> */}
        <div className="row">
          <p className="col-sm">
            &copy; {new Date().getFullYear()} theSilmarillion | All rights
            reserved | theSilmarillion is not associated with Middle-earth
            Enterprises nor the Tolkien Estates.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;

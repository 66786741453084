import CharacterAvatar from "./CharacterAvatar";
import { Col, Row } from "reactstrap";
function CharacterList(props) {
  let characters = props.characternames.map((characterName, index) => {
    return (
      <Col>
        <CharacterAvatar
          inputs={props.inputs}
          character={characterName}
          avatarsize={props.avatarsize}
          characterid={
            props.type == "search" ? characterName._id : characterName.cid
          }
          type={props.type}
        />
      </Col>
    );
  });

  return <Row xs={props.columnsize}>{characters}</Row>;
}

export default CharacterList;

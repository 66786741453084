import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
} from "reactstrap";
import { Form, FormGroup, Input } from "reactstrap";
import { useStoreActions, useStoreState } from "easy-peasy";

import { characterStyle } from "../../consts/styles";
import { CARD_COLORS } from "../../consts/consts";

export default function EventModal(props) {
  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [priority, setPriority] = useState("LV1");

  const toggle = () => setModal(!modal);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const update = useStoreActions((actions) => actions.chapter.updateEvent);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const delete_ = useStoreActions((actions) => actions.chapter.deleteEvent);
  const add = useStoreActions((actions) => actions.chapter.addEvent);
  const userData = useStoreState((state) => state.chapter.userData);
  const load = () => {
    setEvent(props.event);
    setDescription(props.description);
    setLink(props.link);
  };

  const addEvents = () => {
    const data = {
      chapterid: chapterId,
      event: event,
      description: description,
      link: link,
      priority: priority,
      userid: userData.uid, // CHANGE THIS
    };
    add(data);
    toggle();
  };

  const updateEvent = () => {
    const data = {
      chapterid: chapterId,
      event: event,
      description: description,
      link: link,
      priority: priority,
      eventid: props.eventid,
      userid: userData.uid, // CHANGE THIS
    };
    update(data);
    props.toggle();
  };

  const deleteEvent = () => {
    const data = {
      userid: userData.uid,
      chapterid: chapterId,
      eventid: props.eventid,
    };

    delete_(data);
    toggle();
  };

  useEffect(() => {
    load();
  }, [props.description]);

  return (
    <div>
      <Modal
        isOpen={props.modal}
        fade={false}
        toggle={props.toggle}
        className={props.className}
      >
        <ModalHeader
          toggle={props.toggle}
          style={{
            backgroundColor: "#1E1E22",
            alignItems: "center",
          }}
        >
          {props.type === "UPDATE" ? (
            <span style={characterStyle.titileStyle}>MODIFY EVENT</span>
          ) : (
            <span style={characterStyle.titileStyle}>ADD EVENT</span>
          )}
        </ModalHeader>
        <ModalBody style={{ backgroundColor: "#1E1E22" }}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="text"
                name="event"
                placeholder="Event"
                value={event}
                onChange={(e) => setEvent(e.target.value)}
              />
            </FormGroup>
            {/* <FormGroup>
              <Input
                type="text"
                name="description"
                placeholder="tolkiendateway link.."
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </FormGroup> */}
            <FormGroup>
              <Input
                type="textarea"
                name="description"
                placeholder="what happened?"
                id="exampleText"
                onChange={(e) => setDescription(e.target.value)}
                value={description}
              />
            </FormGroup>
            <Row
              style={{
                paddingLeft: 2,
              }}
            >
              <Col xs="4" style={{ display: "flex", justifyContent: "center" }}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        style={{ backgroundColor: CARD_COLORS.LV1 }}
                      >
                        <Input
                          addon
                          type="radio"
                          name="LV1"
                          checked={priority === "LV1"}
                          onChange={() => setPriority("LV1")}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>P1</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="4" style={{ display: "flex", justifyContent: "center" }}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        style={{ backgroundColor: CARD_COLORS.LV2 }}
                      >
                        <Input
                          addon
                          type="radio"
                          name="LV2"
                          checked={priority === "LV2"}
                          onChange={() => setPriority("LV2")}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>P2</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs="4" style={{ display: "flex", justifyContent: "center" }}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText
                        style={{ backgroundColor: CARD_COLORS.LV3 }}
                      >
                        <Input
                          addon
                          type="radio"
                          name="LV3"
                          checked={priority === "LV3"}
                          onChange={() => setPriority("LV3")}
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>P3</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter style={{ backgroundColor: "#1E1E22" }}>
          {props.type === "UPDATE" ? (
            <div>
              <Button
                style={{ marginRight: 20 }}
                color="success"
                onClick={updateEvent}
              >
                Update
              </Button>
              <Button
                style={{ marginRight: 20 }}
                color="danger"
                onClick={deleteEvent}
              >
                Delete
              </Button>
              <Button color="secondary" onClick={() => props.toggle()}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Button
                style={{ marginRight: 20 }}
                color="success"
                onClick={addEvents}
              >
                Add
              </Button>

              <Button color="secondary" onClick={() => props.toggle()}>
                Cancel
              </Button>
            </div>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
}

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import { mapStyles } from "../../consts/styles";
import LocationDetails from "./LocationDetails";
import Map from "./Map";

const MapContainer = (props) => {
  const { className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Modal
        style={mapStyles.mapModal}
        isOpen={props.modal}
        fade={false}
        toggle={props.toggle}
        className={className}
      >
        <ModalHeader style={mapStyles.modalHeader} toggle={toggle}>
          <span style={mapStyles.modalHeaderFont}>ARDA MAP - FIRST AGE</span>
        </ModalHeader>
        <ModalBody style={mapStyles.modalBody}>
          <Row>
            <Container className="themed-container" fluid={true}>
              <Map />
            </Container>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MapContainer;

import React, { useState } from "react";
import AddButton from "../buttons/AddButton";
import { useStoreActions, useStoreState } from "easy-peasy";
import Login from "../common/headers/Login";
import EventModal from "./EventModal";

const AddEvent = () => {
  const [modal, setModal] = useState(false);
  const [event, setEvent] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");

  const toggle = () => setModal(!modal);

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const add = useStoreActions((actions) => actions.chapter.addEvent);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const isLogged = useStoreState((state) => state.chapter.isLogged);

  return (
    <div>
      <AddButton onClick={isLogged ? toggle : toggle1} />
      <Login modal={modal1} toggle={toggle1} />
      <EventModal
        modal={modal}
        event={event}
        description={description}
        link={link}
        handleSubmit={handleSubmit}
        toggle={toggle}
        setEvent={setEvent}
        setDescription={setDescription}
        setLink={setLink}
      />
    </div>
  );
};

export default AddEvent;

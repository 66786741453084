export const API_URL = {
  CHAPTER_URL: "https://api.silmarillions.com/chapters",
  CHARACTER_URL: "https://api.silmarillions.com/characters",
  LOCATION_URL: "https://api.silmarillions.com/locations",
  IMAGE_URL:
    "https://storage.googleapis.com/tolkienlore-ec43c.appspot.com/Characters/",
  IMAGE_LOCATION_URL:
    "https://storage.googleapis.com/tolkienlore-ec43c.appspot.com/Locations/",
  // CHAPTER_URL: "https://tolkien-database-api.herokuapp.com/chapters",
  // CHARACTER_URL: "https://tolkien-database-api.herokuapp.com/characters",
  // LOCATION_URL: "https://tolkien-database-api.herokuapp.com/locations",
};

import axios from "axios";
import { API_URL } from "../config/URL";

export const searchCharacters = (payload) => {
  const URL = API_URL.CHARACTER_URL + `/findCharacter`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getCharacter = (payload) => {
  const URL = API_URL.CHARACTER_URL + `/getCharacter`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

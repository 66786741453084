import LocationItem from "./LocationItem";
import { CardColumns } from "reactstrap";
function LocationList(props) {
  let Locations = props.locations.map((location, index) => {
    return (
      <LocationItem
        locationArr={location}
        width={props.cardwidth}
        margin={props.cardmargin}
      />
    );
  });

  return <CardColumns>{Locations}</CardColumns>;
}

export default LocationList;

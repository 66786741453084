import axios from "axios";
import { API_URL } from "../config/URL";

export const allChapters = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/getAllChapters`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const addEvent = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/addEvent`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateEvent = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/updateEvent`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteEvent = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/deleteEvent`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const addCharacter = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/addCharacters`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getSummary = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/getSummary`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateSummary = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/updateSummary`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const addDictionary = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/addDictionary`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteDictionary = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/deleteDictionary`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const addLocation = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/addLocation`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteLocation = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/deleteLocation`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const deleteCharacter = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/deleteCharacter`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const wordSearch = (payload) => {
  const URL = API_URL.CHAPTER_URL + `/wordSearch`;
  return axios(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: payload,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { FcBookmark } from "react-icons/fc";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";
import InfoIcon from "@material-ui/icons/Info";
import About from "./About";
import Login from "./Login";
import { useStoreState, useStoreActions } from "easy-peasy";
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#1E1E22",
    color: "#BCBCBC",
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const setChapter = useStoreActions((actions) => actions.chapter.setChapter);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
          fontSize: "20px",
          fontWeight: "bold",
        }}
      >
        <FcBookmark />
        CHAPTERS
      </div>
      <p style={{ textAlign: "center" }}>Login to Access All </p>
      <List>
        {["Ainulindalë", "Valaquenta"].map((text, index) => (
          <ListItem disabled={index === 0 ? false : true} button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider light={true} />
      <List>
        {[
          "Of the Beginning of Days",
          "Of Aulë and Yavanna",
          "Of the Coming of the Elves and the Captivity of Melkor",
          "Of Thingol and Melian",
          "Of Eldamar and the Princes of the Eldalië",
          "Of Fëanor and the Unchaining of Melkor",
          "Of the Silmarils and the Unrest of the Noldor",
          "Of the Darkening of Valinor",
          "Of the Flight of the Noldor",
          "Of the Sindar",
          "Of the Sun and Moon and the Hiding of Valinor",
          "Of Men",
          "Of the Return of the Noldor",
          "Of Beleriand and its Realms",
          "Of the Noldor in Beleriand",
          "Of Maeglin",
          "Of the Coming of Men into the West",
          "Of the Ruin of Beleriand and the Fall of Fingolfin",
          "Of Beren and Lúthien",
          "Of the Fifth Battle: Nirnaeth Arnoediad",
          "Of Túrin Turambar",
          "Of the Ruin of Doriath",
          "Of Tuor and the Fall of Gondolin",
          "Of the Voyage of Eärendil and the War of Wrath",
        ].map((text, index) => (
          <ListItem disabled button key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider classes={{ root: classes.divider }} />
      <List>
        {["Akallabêth", "Of the Rings of Power and the Third Age"].map(
          (text, index) => (
            <ListItem disabled button key={text}>
              <ListItemText primary={text} />
            </ListItem>
          )
        )}
      </List>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "#1E1E22",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button style={{ outline: 0 }} onClick={toggleDrawer(anchor, true)}>
            <MenuIcon style={{ color: "white" }} />
          </Button>
          <SwipeableDrawer
            classes={{ paper: classes.paper }}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}

      <div style={{ marginTop: "10px", marginRight: "30px" }}>
        <About modal={modal} toggle={toggle} />
        <Login modal={modal1} toggle={toggle1} />
        <Button
          onClick={toggle}
          style={{
            outline: 0,
            paddingTop: "10px",
            paddingBottom: "10px",
            fontWeight: "bold",
            fontSize: "14px",
            backgroundColor: "#E3E303",
          }}
          variant="contained"
          className={classes.button}
          endIcon={<InfoIcon />}
        >
          Donate
        </Button>
        <Button
          onClick={toggle1}
          style={{
            marginLeft: "30px",
            outline: 0,

            fontWeight: "bold",
            paddingTop: "10px",
            paddingBottom: "10px",

            fontSize: "14px",
          }}
          variant="contained"
          className={classes.button}
          endIcon={<EmojiEmotionsIcon />}
        >
          Login
        </Button>
      </div>
    </div>
  );
}

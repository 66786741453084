import { CardTitle, Card, CardBody, CardText, CardFooter } from "reactstrap";
import React, { useState } from "react";
import { eventStyles } from "../../consts/styles";
import { CARD_COLORS } from "../../consts/consts";
import EventModal from "./EventModal";
import { ImLink } from "react-icons/im";
import { useStoreActions, useStoreState } from "easy-peasy";
import Login from "../common/headers/Login";
function EvenItem(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const isLogged = useStoreState((state) => state.chapter.isLogged);

  const getImportance = (color) => {
    switch (color) {
      case "LV1":
        return CARD_COLORS.LV1;
        break;
      case "LV2":
        return CARD_COLORS.LV2;
        break;
      case "LV3":
        return CARD_COLORS.LV3;
        break;
      default:
        break;
    }
  };

  return (
    <div onClick={isLogged ? toggle : toggle1} style={{ cursor: "pointer" }}>
      <Login modal={modal1} toggle={toggle1} />
      <Card style={{ width: props.width, margin: props.margin }}>
        <CardBody
          style={{
            backgroundColor: getImportance(props.eventArr.priority),
            borderRadius: 3,
          }}
        >
          <CardTitle style={eventStyles.textStyle} tag="h5">
            {props.eventArr.event}
          </CardTitle>
          <CardText style={eventStyles.textDescription}>
            {props.eventArr.description}
          </CardText>
          <div>{/* <CharacterBar /> */}</div>
        </CardBody>
        <CardFooter className="text-muted">
          <ImLink />
          <span>
            <a
              style={eventStyles.arefStyles}
              href={props.eventArr.link}
              onClick={() =>
                window.open(
                  `https://www.google.com/search?q=` +
                    props.eventArr.event +
                    " tolkiengateway.net",
                  "_blank"
                )
              }
            >
              tolkiengateway.net
            </a>
          </span>
        </CardFooter>
      </Card>
      <EventModal
        modal={modal}
        event={props.eventArr.event}
        eventid={props.eventArr.eventid}
        type="UPDATE"
        description={props.eventArr.description}
        link={props.eventArr.link}
        toggle={toggle}
      />
    </div>
  );
}

export default EvenItem;

import React from "react";
import SpinnerLoading from "../components/common/SpinnerLoading";
import { loadingStyle } from "../consts/styles";

export default function Loading() {
  return (
    <div style={loadingStyle.loaderStyle}>
      <SpinnerLoading />
    </div>
  );
}

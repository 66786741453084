import React from "react";
import { Spinner } from "reactstrap";
export default function SpinnerLoading() {
  return (
    <div>
      <Spinner style={{ margin: 5 }} type="grow" color="primary" />
      <Spinner style={{ margin: 5 }} type="grow" color="secondary" />
      <Spinner style={{ margin: 5 }} type="grow" color="success" />
      <Spinner style={{ margin: 5 }} type="grow" color="danger" />
      <Spinner style={{ margin: 5 }} type="grow" color="warning" />
      <Spinner style={{ margin: 5 }} type="grow" color="info" />
      <Spinner style={{ margin: 5 }} type="grow" color="light" />
      <Spinner style={{ margin: 5 }} type="grow" color="dark" />
    </div>
  );
}

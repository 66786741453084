import React from "react";
import SectionLayout from "../layouts/SectionLayout";
import SectionTitle from "../common/SectionTitle";
import textdata from "../../api/summary";
import SummaryContainer from "../summary/SummaryContainer";
import { BsLayoutTextWindow } from "react-icons/bs";
import AddSummary from "../summary/AddSummary";
import { mapStyles } from "../../consts/styles";

function SummarySection(props) {
  return (
    <SectionLayout width="100%">
      <div style={mapStyles.mapDiv}>
        <SectionTitle
          titlename="SUMMARY"
          icon={<BsLayoutTextWindow size="1.2em" />}
        />
        <AddSummary textdata={props.chapters.summary} />
      </div>

      <SummaryContainer
        textdata={props.chapters.summary}
        width={"100%"}
        margin={10}
        height="540px"
      />
    </SectionLayout>
  );
}
export default React.memo(SummarySection);

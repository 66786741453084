import React, { useState } from "react";
import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";
import { locationStyle } from "../../consts/styles";
import { API_URL } from "../../config/URL";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import Login from "../common/headers/Login";

import location_img from "../../images/location.png";
export default function LocationItem(props) {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  const userData = useStoreState((state) => state.chapter.userData);
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const delete_ = useStoreActions((actions) => actions.chapter.deleteLocation);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);
  const isLogged = useStoreState((state) => state.chapter.isLogged);

  const deleteEvent = () => {
    const data = {
      userid: userData.uid,
      chapterid: chapterId,
      name: props.locationArr.name,
    };
    delete_(data);

    toggle();
  };

  return (
    <div style={{ cursor: "pointer" }} onClick={isLogged ? toggle : toggle1}>
      <Login modal={modal1} toggle={toggle1} />
      <Card style={{ width: props.width, margin: props.margin }}>
        <img
          top
          width="100%"
          height="150px"
          src={API_URL.IMAGE_LOCATION_URL + props.locationArr.name + ".jpg"}
          alt="Card image cap"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = location_img;
          }}
        />
        <CardBody style={locationStyle.cardBody}>
          <CardTitle tag="h5">{props.locationArr.name}</CardTitle>
          <CardText>{props.locationArr.desc}</CardText>
        </CardBody>
      </Card>
      <Modal isOpen={modal} fade={false} toggle={toggle}>
        <ModalBody style={locationStyle.modalStyles}>
          <span style={locationStyle.modalText}>
            Are you sure you want to{" "}
            <span style={{ color: "white" }}>delete</span>{" "}
            {props.locationArr.name} ?
          </span>
        </ModalBody>
        <ModalFooter style={locationStyle.modalStyles}>
          <Button color="danger" onClick={deleteEvent}>
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

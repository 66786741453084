import SummaryBody from "./SummaryBody";
export default function SummaryContainer(props) {
  return (
    <div
      style={{
        overflowY: "scroll",
        maxHeight: props.height,
        width: props.width,
        padding: "10px",
        margin: props.margin,
      }}
    >
      <SummaryBody textdata={props.textdata} />
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import { useStoreState, useStoreActions } from "easy-peasy";
import logo from "../../../images/logo.png";
import { dictStyles } from "../../../consts/styles";
const config = {
  apiKey: "AIzaSyBV3JRL4ob77Mm7N2cPTzZqJ9QaNaAKN-o",
  authDomain: "tolkienlore-6a0ec.firebaseapp.com",
  // ...
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
} else {
  firebase.app(); // if already initialized, use that one
}

function Login(props) {
  const setIsLogged = useStoreActions((actions) => actions.chapter.setIsLogged);
  const setUser = useStoreActions((actions) => actions.chapter.setUser);
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (!!user) {
          setIsLogged(true);
          setUser(user);
        }
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  const [modal, setModal] = useState(false);

  const uiConfig = {
    signInFlow: "popup",
    signInSuccessUrl: "/hello",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };
  const isLogged = useStoreState((state) => state.chapter.isLogged);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Modal
        backdrop="static"
        isOpen={isLogged ? false : props.modal}
        fade={true}
        toggle={props.toggle}
        style={{ width: "100%" }}
      >
        <ModalHeader style={dictStyles.titleStyle} toggle={props.toggle}>
          Login
        </ModalHeader>
        <ModalBody
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#1E1E22",
          }}
        >
          <img src={logo} width="280px" style={{ marginBottom: "20px" }} />
          <p
            style={{
              color: "#8B9E26",
              fontFamily: "Fredoka One",
              fontSize: "16px",
            }}
          >
            login to use the application!{" "}
          </p>
          <StyledFirebaseAuth
            uiConfig={uiConfig}
            firebaseAuth={firebase.auth()}
          />

          {/* <div style={{ marginTop: "20px" }}>
            <Button color="warning">Donate</Button>
            <Button
              style={{ marginLeft: "20px" }}
              color="secondary"
              onClick={props.toggle}
            >
              Cancel
            </Button>
          </div> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default React.memo(Login);

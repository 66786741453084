import EventItem from "./EventItem";
import { Col, Row } from "reactstrap";
import React from "react";

function EventList(props) {
  let Events = props.events.map((event, index) => {
    return (
      <Col style={{ marginTop: props.marginTop }}>
        <EventItem
          key={index}
          eventArr={event}
          width={props.cardwidth}
          margin={props.cardmargin}
        />
      </Col>
    );
  });

  return <Row xs={1}>{Events}</Row>;
}

export default React.memo(EventList);

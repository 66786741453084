import { Button } from "reactstrap";
import { dictStyles } from "../../consts/styles";
import { Popover, OverlayTrigger } from "react-bootstrap";

export default function DictionaryItem(props) {
  const shortText = props.words.shorttext;
  const longText = props.words.longtext;
  const word = props.words.word;

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">{shortText}</Popover.Title>
      <Popover.Content>{longText}</Popover.Content>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" placement="top" overlay={popover}>
        <Button style={dictStyles.buttonStyles} outline variant="success">
          {word}
        </Button>
      </OverlayTrigger>
    </div>
  );
}

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Form,
  Table,
} from "reactstrap";
import { useStoreState, useStoreActions } from "easy-peasy";
import IconButton from "@material-ui/core/IconButton";
import { dictStyles } from "../../consts/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";

export default function DictionaryModal(props) {
  const [modal, setModal] = useState(false);
  const [word, setWord] = useState("");
  const [short, setShort] = useState("");
  const [long, setLong] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [noun, setNoun] = useState("");
  const [verb, setVerb] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
  };

  const add = useStoreActions((actions) => actions.chapter.addDictionary);
  const search = useStoreActions((actions) => actions.chapter.wordSearch);
  const deleteDictionary = useStoreActions(
    (actions) => actions.chapter.deleteDictionary
  );
  const chapterId = useStoreState((state) => state.chapter.chapterId);
  const userData = useStoreState((state) => state.chapter.userData);
  const wordList = useStoreState((state) => state.chapter.wordList);
  const addDictionary = () => {
    const payload = {
      word: word,
      shorttext: short,
      longtext: long,
      chapterid: chapterId,
      userid: userData.uid, // CHANGE THIS
    };
    add(payload);
  };

  const addDictionaryFromDict = (searchedWord) => {
    let payload = {};
    {
      Object.keys(wordList[0].meaning).map(function (key) {
        payload = {
          word: searchedWord,
          shorttext: "Definition",
          longtext: !("noun" in wordList[0].meaning)
            ? !("verb" in wordList[0].meaning)
              ? wordList[0].meaning["transitive verb"][0].definition
              : wordList[0].meaning["verb"][0].definition
            : wordList[0].meaning["noun"][0].definition,
          chapterid: chapterId,
          userid: userData.uid, // CHANGE THIS
        };
      });
    }

    add(payload);
  };

  const deleteDict = (wordid) => {
    const payload = {
      wordid: wordid,
      chapterid: chapterId,
      userid: userData.uid, // CHANGE THIS.
    };

    deleteDictionary(payload);
  };

  let tablewords = props.data.map((dictwords, index) => {
    let counter = 1;
    return (
      <tr>
        <th scope="row">{index + 1}</th>
        <td>{dictwords.word}</td>
        <td>
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button color="danger" onClick={() => deleteDict(dictwords.wordid)}>
              Delete
            </Button>
            {}
            {/* <Button
              style={{ marginLeft: 10 }}
              color="warning"
              onClick={props.toggle}
            >
              View
            </Button> */}
          </div>
        </td>
      </tr>
    );
  });

  const getDefinition = () => {
    let payload = {
      searchTerm: searchTerm,
    };
    search(payload);
    console.log(wordList.length);
    console.log(wordList);
  };

  return (
    <div>
      <Modal
        style={{ width: "100%" }}
        isOpen={props.modal}
        fade={false}
        toggle={props.toggle}
      >
        <ModalHeader style={dictStyles.modalStyle} toggle={props.toggle}>
          <span style={dictStyles.titleStyle}>MODIFY DICTIONARY</span>
        </ModalHeader>
        <ModalBody style={dictStyles.modalBodyStyle}>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Input
                type="text"
                name="search"
                placeholder="Search Word"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </FormGroup>
            <div>
              <Button color="primary" onClick={getDefinition}>
                Search
              </Button>{" "}
            </div>
          </Form>
          <div
            style={{
              color: "white",
              marginTop: "8px",
              display: "flex",
              flexWrap: "wrap",
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            {wordList.length > 0 && wordList.length < 10 ? (
              <span
                style={{
                  color: "white",
                  marginTop: "5px",
                  fontWeight: "bold",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                  }}
                >
                  <span style={{ marginRight: "10px" }}>
                    {wordList[0].word}
                  </span>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => addDictionaryFromDict(wordList[0].word)}
                  >
                    <AddCircleIcon
                      style={{ color: "green" }}
                      fontSize="inherit"
                    />
                  </IconButton>
                </div>

                {Object.keys(wordList[0].meaning).map(function (key) {
                  return (
                    <div>
                      <span style={{ color: "tomato" }}>{key}</span>
                      <p> {wordList[0].meaning[key][0].definition}</p>
                    </div>
                  );
                })}
              </span>
            ) : null}
          </div>
          <Form onSubmit={handleSubmit} style={{ marginTop: "30px" }}>
            <FormGroup>
              <Input
                type="text"
                name="word"
                placeholder="Word"
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Input
                type="textarea"
                name="long"
                placeholder="Definition"
                value={long}
                onChange={(e) => setLong(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div>
            <Button color="success" onClick={addDictionary}>
              Add
            </Button>{" "}
            {/* <Button color="success" onClick={props.toggle}>
              Update
            </Button>{" "} */}
            <Button color="secondary" onClick={props.toggle}>
              Cancel
            </Button>
          </div>
          <div
            style={{
              maxHeight: "200px",
              overflowY: "auto",

              marginTop: 20,
            }}
          >
            <Table dark>
              <tbody>{tablewords}</tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

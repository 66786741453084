import { createStore, persist } from "easy-peasy";
import characterModel from "./characterModel";
import chapterModel from "./chapterModel";
import mapModel from "./mapModel";
import locationModel from "./locationModel";

const model = {};

const store = createStore(persist(model));

store.addModel("character", characterModel);
store.addModel("chapter", chapterModel);
store.addModel("map", mapModel);
store.addModel("location", locationModel);

export default store;

import { Container } from "reactstrap";
import CharacterList from "./CharacterList";

export default function CharacterGroups(props) {
  return (
    <div
      fluid
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        width: props.width + "%",
        overflowX: "hidden",
        overflowY: "scroll",
        whiteSpace: "nowrap",
        maxHeight: props.maxHeight,
      }}
    >
      <CharacterList
        inputs={props.inputs}
        characternames={props.characternames}
        columnsize={3}
        avatarsize={props.avatarsize}
        type={props.type}
      />
    </div>
  );
}
